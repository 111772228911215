import { createSlice } from '@reduxjs/toolkit'
const img1 = "./assets/images/partners/logo512.png";
const img2 = "./assets/images/partners/logo-full.png";

const partnersSlice = createSlice({
    name: 'partners',
    initialState: {
      payload: [
        {
          img: '/assets/images/partners/nnpc.png',
          title: 'NNPC'
        },
        {
          img: '/assets/images/partners/cbn.jpg',
          title: 'CBN'
        },
        {
            img: '/assets/images/partners/cac.jpg',
            title: 'CAC'
          },
          {
            img: '/assets/images/partners/anan.jpg',
            title: 'ANAN'
          },
      ]
    }
    ,
    reducers: {
        partners: (state, action) => {
            state.sliders = action.payload;
        }
    }
})

export const {partners} = partnersSlice.actions;
export default partnersSlice.reducer;
