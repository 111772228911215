import React from 'react'
import { useSelector } from 'react-redux'

const About = () => {
    const {sliders} = useSelector((state) => state.sliders);

  return (
    <div className='py-5'>
              <p className='small-header text-center'> WHO WE ARE </p>
              <div className='row '>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-2 container'>
                        <img src={'./assets/images/sliders/slider1.jpg'} width={'100%'} height={'400px'} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 container'
                    style={{ 
                        textAlign: 'justify'
                     }}
                    >
                    <p className='small-header mt-0'>ABOUT US </p>
                    <p className='px-2'> 
                      Writeups about the company selling the company to potential clients.
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clientsv
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clients
                    Writeups about the company selling the company to potential clients
                    
                    </p>
                    </div>
              </div>
        </div>
  )
}

export default About