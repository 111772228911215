import React from 'react'
import { useSelector } from 'react-redux'

const ClientRemarks = () => {
    const clients = useSelector(state => state.clients.payload);
    console.log(clients);
  return (
    <div className='row justify-content-center p-5'
    style={{ 
        backgroundColor: '#222B40',
        color: 'white',
        // height: '800px'
     }}
    >
        <p className='small-header mt-2 text-center'> OUR CLIENTS REMARKS  </p>
        
            {
                clients.map((client) => (
                    
                <div className='col-lg-4 col-md-4 col-sm-6 col-xs12'>
                    <div className='card mb-3' style={{ 
                        
                        padding: '5px'
                        }}>
                          <div className='d-flex justify-content-center'>
                                <span className='d-flex justify-content-center align-items-center'
                                style={{ 
                                    borderRadius:'100%',
                                width: '100px',
                                height: '100px'
                                }}
                                > 
                                <img src={client.img} width={'100px'} height={'100px'} 
                                style={{ 
                                    borderRadius: '100%'
                                }}
                                />
                                </span>
                          </div>
                          <p className='ms-2'
                          style={{ 
                            fontSize: 13,
                            textAlign: 'justify'
                           }}
                          > {client.remark} </p> 
                          <p className='ms-2 mb-0'> <b>{client.name }</b> </p>
                          <p className='ms-2 mt-0'
                          style={{ fontSize: 11 }}
                          > <i>{client.position} <br/>{'('+client.company +')'}</i> </p>
                    </div>
                </div>
                ))
            }
        
        
        <div className='col-lg-4 col-md-4 col-sm-6 col-xs12'>

        </div>
    </div>
  )
}

export default ClientRemarks