import React from 'react'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap';

const Partners = () => {
    const partners = useSelector(state => state.partners.payload);
    let i = 0;
  return (
    <div className='row py-5'>
    <div className='row  justify-content-center'
    id='partners'
    >
        <p className='small-header mt-2 text-center'> <u>OUR PARTNERS</u>  </p>
        <Carousel variant='dark'
        indicators={false}
        controls = {false}
            className='eventSlider row mt-2 col-lg-4 col-md-4 col-sm-6 col-xs-12'
            
            >
            {
                partners.map((partner, index) => (
                
                <Carousel.Item key={index}
                className='col-lg-3 col-md-3 col-sm-6 col-xs-12'
                variant='dark'
                
                >
                   <div className='d-flex justify-content-center text-center align-items-center' 
                    style={{ 
                        
                    // color: 'white',
                    padding: '5px'
                    }}>
                <span className='text-center bg-primary d-flex justify-content-center text-center align-items-center'
                          style={{ 
                            borderRadius:'100%',
                        width: '150px',
                        height: '150px',
                        backgroundColor: 'navy',
                           }}
                          > 
                    <img src={partner.img} width={'150px'} height={'150px'} 
                          style={{ 
                            borderRadius: '100%'
                           }}
                      />
                </span>
                </div>
                </Carousel.Item>
                ))
            }
        
        </Carousel>

         <Carousel variant='dark'
        indicators={false}
        controls = {false}
            className='eventSlider row mt-2 col-lg-4 col-md-4 col-sm-6 col-xs-12'
            
            >
            {
                partners.map((partner, index) => {
                  // let i = Number(index) + 1;
                  
                  if(partners.length <= index + 1){
                    i = 0
                  }else{
                    i = index + 1
                  }
                  // if(index == 0){
                  //   i = 1
                  // }else if(index == 1){
                  //   i = 2
                  // }


                  console.log(i);
                  return (
                
                    <Carousel.Item key={index}
                className='col-lg-3 col-md-3 col-sm-6 col-xs-12'
                variant='dark'
                
                >
                    {/* <span className='col-4'> */}
                    <div className='d-flex justify-content-center text-center align-items-center' 
                    style={{ 
                        
                    // color: 'white',
                    padding: '5px'
                    }}>
                <span className='text-center bg-primary d-flex justify-content-center text-center align-items-center'
                          style={{ 
                            borderRadius:'100%',
                        width: '150px',
                        height: '150px',
                        backgroundColor: 'navy',
                           }}
                          > 
                      <img src={partners[i].img} width={'150px'} height={'150px'} 
                          style={{ 
                            borderRadius: '100%'
                           }}
                          />
                </span>
                {/* <h3> {partners[i].title} </h3> */}
                </div>
                    
                      
                    </Carousel.Item>
                    )
                })
            }
        
        </Carousel>

         <Carousel variant='dark'
        indicators={false}
        controls = {false}
            className='eventSlider row mt-2 col-lg-4 col-md-4 col-sm-6 col-xs-12'
            
            >
            {
                partners.map((partner, index) => {
                  // let i = Number(index) + 1;
                  
                  if(partners.length <= index + 2){
                    i = 1
                  }else{
                    i = index + 2
                  }
                  console.log(i);
                  return (
                
                    <Carousel.Item key={index}
                className='col-lg-3 col-md-3 col-sm-6 col-xs-12'
                variant='dark'
                
                >
                   <div className='d-flex justify-content-center text-center align-items-center' 
                    style={{ 
                        
                    // color: 'white',
                    padding: '5px'
                    }}>
                <span className='text-center bg-primary d-flex justify-content-center text-center align-items-center'
                          style={{ 
                            borderRadius:'100%',
                        width: '150px',
                        height: '150px',
                        backgroundColor: 'navy',
                           }}
                          > 
                      <img src={partners[i].img} width={'150px'} height={'150px'} 
                          style={{ 
                            borderRadius: '100%'
                           }}
                          />
                </span>
                {/* <h3> {partners[i].title} </h3> */}
                </div>
                    
                    </Carousel.Item>
                    )
                })
            }
        
        </Carousel>



    </div>
    </div>
  )
}

export default Partners