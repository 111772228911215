import React, { useState } from 'react'
import { Navbar, Nav, Container, Button } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'

const Header = ({activeState, heading}) => {
  const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState('/');
    const checkPath = (path) => {
      // if(path === location.pathname){
        if(path === active){
        // return 'active'
        return 'fw-bold active'
      }else{
        return ''
      } 
    }
    console.log(location);
  return (
    <>   
    <Navbar sticky='top' expand="md"  data-bs-theme="light" collapseOnSelect
    className='topBar py-2'
    style={{ 
      position: 'fixed',
      height: '60px',
      width: '100%'
      // backgroundColor: '#9A9BB4',
      // backgroundColor: 'aliceblue'
     }}
    >
      {/* <Container> */}
        <Navbar.Brand className='ms-3 py-0 d-flex align-items-center' href="#"
        
        >
            <img src='/abaafashab3.png'
          style={{ 
            borderTopLeftRadius: '25%',
            borderTopRightRadius: '25%',
            // borderBottomLeftRadius: '5%',
            // borderBottomRightRadius: '5%',
            // borderRadius: '50%',
            width:'9rem',
            height: '45px',
            // borderRadius: '10%'
           }}
          />
          
        </Navbar.Brand>
        <Navbar.Toggle className='me-3' variant='outline-dark' aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav"
        className='mx-3'
        style={{ 
          backgroundColor: 'inherit'
         }}
        >
          <Nav className="header-link-body ms-auto d-flex justify-content-end my-0">
            <Nav.Link 
            
            onClick={() => {
                  window.scrollTo({
                    top: 0
                  })
                  setActive('/');
            }}
            href="#">
              <a 
              style={{ 
                // border: 0,
                // borderColor: 'white',
                fontSize:'13px',
                fontWeight: '600'
                // borderStyle: 'dashed'
               }}
               className={'header-btn btn form-control text-start ' + checkPath('/')}>
                Home
              </a>
            </Nav.Link>
            <Nav.Link 
            
            onClick={() => {
              const el = document.getElementById('services');
              el.scrollIntoView({behavior: 'smooth', offsetTop: 50});
                  setActive('/services');
            }}
            href="#">
              <a 
              style={{ 
                // border: 0,
                // borderColor: 'white',
                fontSize:'13px',
                fontWeight: '600'
                // borderStyle: 'dashed'
               }}
               className={'header-btn btn form-control text-start ' + checkPath('/services')}>
                Our Services
              </a>
            </Nav.Link>
            <Nav.Link 
            
            onClick={() => {
              const el = document.getElementById('about');
                el.scrollIntoView({behavior: 'smooth', offsetTop: 0});
                // console.log(el.getBoundingClientRect());
                // console.log(window.pageYOffset)
                // console.log(el.offsetTop);
                setActive('/about')
            }}
            href="#">
              <a 
              style={{ 
                // border: 0,
                // borderColor: 'white',
                // borderStyle: 'dashed'
                fontSize:'13px',
                fontWeight: '600'
               }}
               className={'header-btn btn form-control text-start ' + checkPath('/about')}>
                About Us
              </a>
            </Nav.Link>
            <Nav.Link 
            
            onClick={() => {
              const el = document.getElementById('contact');
                el.scrollIntoView({behavior: 'smooth', });
                // el.scrollTo({top: 0})
                console.log(el.getBoundingClientRect());
                setActive('/contact')
            }}
            href="#">
              <a 
              id='contact-btn'
              style={{ 
                fontSize:'13px',
                fontWeight: '600'
               }}
               className={'header-btn btn form-control text-start ' + checkPath('/contact')}>
                Contact Us
              </a>
            </Nav.Link>
            <Nav.Link 
            
            onClick={() => {
              navigate('/login')
            }}
            href="#">
              <a 
              id='contact-btn'
              style={{ 
                fontSize:'13px',
                fontWeight: '600'
               }}
               className={'header-btn btn form-control text-start '}>
                Sign-in
              </a>
            </Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      {/* </Container> */}
    </Navbar>
    {/* {
      heading && 
      <div className=' text-white d-flex align-items-center header-container'
      >
      <h1 className='ms-4 header'>{heading} </h1>
      </div>
    } */}
    </>
  )
}

export default Header
